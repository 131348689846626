.homeHeader {
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  border-width: 3px;
  border-color: #a61111;
  border-style: outset;
  margin-top: 2px;
  color: #fefefe;
}

.main-div {
  background-color: black;
}

.marquee-1 {
  color: red;
  font-weight: bold;
}

.marquee-2 {
  color: rgb(230, 241, 10);
  font-weight: bold;
}

.homeHeader,
.header-heading {
  background-color: #e5ffe0;
  padding: 3px 10px;
  border-radius: 10px;
  text-align: center;
}

.homeHeader .main-row,
.section .main-row {
  padding: 10px;
}

.homeHeader a {
  text-decoration: none;
  color: #00780c;
  cursor: pointer;
}

.homeHeader .link {
  border: 1px solid #000;
  padding: 8px;
}

.header-heading h1,
.bottomResult h1 {
  color: #a10000;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
}

.header-heading h6 {
  color: #000;
  font-weight: bold;
  font-size: 14px;
}

.advertisement {
  background-color: #ffffe0;
  text-align: center;
  font-weight: bold;
}

.daywisereport h3 {
  background-color: #10922a;
  color: #fff;
  padding: 1rem 0px;
}

.currentBanner h1 {
  color: red;
  font-size: 60px;
  padding: 15px 0px;
  font-weight: bold;
}

.currentBanner p {
  font-size: 70px;
  color: green;
  background-color: #ffffe0;
  border-radius: 45px;
  font-weight: bold;
}

.result-button {
  background-color: red !important;
  color: white !important;
  font-size: medium !important;
  border: none;
  margin-top: 7px;
}

.bottomResult {
  background-color: #ffffe0;
  padding: 1rem 0px;
}

.bottomResult h3 {
  color: green;
  text-shadow: 1px 1px #00f;
  font-size: 50px;
}

.bottomResult hr {
  opacity: 1 !important;
}

.game_column {
  background-color: #f5ecce;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
}

.game_column h6 {
  text-transform: uppercase;
  color: #f00a3b;
  font-weight: bold;
}

.sattaBlack {
  text-align: center;
  background: linear-gradient(to bottom, #093915 45%, #fefefe 85%);
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid #000;
}

.sattaBlack table {
  background-color: linear-gradient(to bottom, #ffffe0 45%, #fefefe 85%);
}

.sattaBlack h5 {
  color: #f1e9e9 !important;
  font-weight: bold;
}

.cardDetails {
  background-color: #e0ffe2 !important;
  color: #000;
  /* font-weight: bold; */
  /* font-style: bold !important; */
  /* font-size: large; */
  text-decoration: none;
  border-width: 3px !important;
  border-color: #a61111 !important;
  border-style: outset !important;
  margin: 3px;
  padding: 10px;
  border-radius: 10px !important;
  text-align: center;
}

.cardDetails button {
  color: #a61111;
  background-color: transparent;
  border: none;
  font-weight: bold;
}

/* ============antd table styling========== */
/* .ant-table-content thead {
  text-align: center !important;
} */

/* .ant-table-content thead tr,
.ant-table-content thead td {
  background-color: #fbc503;
  border: 1px solid #e7aa26;
  border-radius: 0px;
} */

.ant-table-content {
  border: 1px solid #ddd;
}

.tableStyle table {
  border: 2px;
  border-radius: 8px !important;
}

.ant-table-thead > tr > td,
.ant-table-thead th {
  border-radius: 0px !important;
  border-color: #000;
  background-color: transparent !important;
  color: #000 !important;
  font-weight: bold;
  text-wrap: nowrap;
}

.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child
  .ant-table-content {
  border-start-start-radius: 8px !important;
}

.ant-table-content tr {
  text-align: center !important;
  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
  /* background-color: red; */
}

/* .ant-table-cell.ant-table-cell th:first-child{
  background-color: green !important;
} */

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  /* background-color: #B76856 !important;
  color: #A61111 !important; */
  padding: 0rem 1rem !important;
}

/* 
.ant-table-wrapper .ant-table-container table>tbody>tr>*:first-child {
  background-color: #ffddff !important;
} */

.ant-table-content td,
.ant-table-content th {
  padding: 10px 5px !important;
  text-align: center !important;

  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

.ant-table-content tr:nth-child(even) {
  background-color: #fff !important;
}

.ant-table-content tr:nth-child(odd) {
  background-color: #c7e5c9 !important;
}

.ant-table-wrapper table {
  border-radius: 0px !important;
  /* margin-top: -6px; */
}

/* table tbody tr:hover {
  background-color: #f0f0f0;
  color: red;
} */

/* ========disclaimer======= */
.disclaimer p,
.disclaimer span {
  background-color: #fff;
}

.disclaimer .header {
  border-style: double;
  border-color: blue;
  background-color: purple;
  color: #fdeef4;
  margin-bottom: 0.5rem;
}

.header_2 {
  background-color: lightyellow;
  font-style: bold;
  border-color: red;
  border-style: outset;
  padding: 10px;
  border-radius: 10px;
}

.section .link {
  padding: 8px 0px;
}

.section .link a {
  text-decoration: none;
  color: #fff;
}

/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

#go-button {
  background-color: rgb(24, 109, 237);
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: navy;
}

/* refresh button */
.refreshButton {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 33px;
  width: 110px;
  background-color: red;
  color: #fff;
  border: double 3px red;
  border-radius: 18px;
  z-index: 1;
}

/* footer button styling */
.footerButton button {
  background: red;
  width: 100%;
  border-radius: 1rem;
  border: 0px;
  color: #fff;
}

.footerButton .last-button {
  width: 32%;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #837e7e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #837e7e;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #c1c6c2;
}

/* ============old style========== */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.fqa .accordion-button {
  color: rgb(255, 0, 0) !important;
  background: linear-gradient(to bottom, #ffffe0, white);
  border: none;
  font-size: 19px;
  font-weight: bold;
}

.fqa .accordion-button:not(.collapsed) {
  background: linear-gradient(to bottom, #edf10356, white);
}

.fqa .accordion-body {
  background-color: white;
  color: rgb(3, 3, 3);
  text-align: center;
}

.accordion-button.custom-accordion-button {
  width: 100%;
  justify-content: center;
  text-align: center;
  position: relative;
}

.accordion-button.custom-accordion-button::after {
  position: absolute;
  right: 10px;
  /* Adjust this value to place the arrow closer to the text */
}

.accordion-button p {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  text-align: center !important;
}

/* detailHeading */
.detailHeading {
  /* background-color: red; */
  margin: 0rem;
  padding: 1rem;
  color: #fff;
  border: 0.5px solid #ddd;
  text-align: center;
  padding: 8px;
  background-color: rgb(139, 0, 0);
}

.disclaimerFooter a {
  color: green;
  text-decoration: none;
}
/* socialIcon */
.socialIcon {
  text-align: center;
  background: #fc6;
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid brown;
}
.socialIcon .svg-inline--fa {
  color: #000;
  margin-right: 1.5rem;
}

.navItem a {
  text-decoration: none;
  color: #000;
}

.monthYrTbl {
  text-transform: uppercase;
}
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}

/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #2c8c06;
  background: linear-gradient(to right, #ff00ff, #bfffff);
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  /* background-color: #faf8fb; */
  /* Light cream background */
  box-shadow: 0 0 20px rgba(250, 247, 247, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: white;
  background-color: #10922a;
  padding: 0.5rem;
  border-radius: 1rem;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #10922a;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}
